import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageBannerImgBackground from '../components/PageBannerImgBackground/pageBannerImgBackground'
import SpotlightActivity from '../components/SpotlightActivity/spotlightActivity'


export const WorkTemplate = ({
  pageBannerDescription,
  pageBannerTitle,
  pageBannerImage,
  workList,
  spotlightActivity
}) => {


  const getImagePath = (image) => {
    if (image?.publicURL) return image.publicURL
    else return image
  }
//   const PageContent = contentComponent || Content
  return (
    <>
    <div className='work-wrapper'>
        <PageBannerImgBackground
        imgPath={getImagePath(pageBannerImage)}
        title={pageBannerTitle}
        description={pageBannerDescription}
        />

            <div className='body-content-wrapper'>
                    {workList && workList.map(item => <div key={item.title} className='description-block'>
                    <a href={item.link}>
                        <div className='image-wrapper' style={{background: `url(${getImagePath(item.thumbnail)} )`}}> 
                        </div> 
                    </a>
                            <div className='description-wrapper'>
                                <div className='title'>
                                <a href={item.link}> {item.title} </a>
                                </div>
                                <div className='client'> {item.client} </div>
                                <div className='technology'> {item.technologies.map((technology, index) => <span>  
                                  {item.technologies.length !== index + 1 ? `${technology} |`  : technology } </span>  )} </div>
                                <div className='description'>
                                    {item.description}
                                </div>
                                <button className='btn-default'><a href={item.link}> Read More </a></button>
                            </div>
                    </div>)}
                </div>

        </div> 
    </>
  )
}

WorkTemplate.propTypes = {
    // bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    workList: PropTypes.array,
    spotlightActivity: PropTypes.array,
    pageBannerDescription: PropTypes.string,
    pageBannerTitle: PropTypes.string,
    pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  const WorkPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.pageBannerTitle}
        description={frontmatter.pageBannerDescription}
        >
              <WorkTemplate
                workList={frontmatter.workList}
                spotlightActivity={frontmatter.spotlightActivity}
                pageBannerDescription={frontmatter.pageBannerDescription}
                pageBannerTitle={frontmatter.pageBannerTitle}
                pageBannerImage={frontmatter.pageBannerImage}
              />
        </Layout>
      )
}
 
WorkPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default WorkPage


export const pageQuery = graphql`
  query WorkTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "work-hub" } }) {
      frontmatter {
        pageBannerDescription
        pageBannerTitle
        pageBannerImage {
          extension
          publicURL
        } 
        workList {
            title
            client
            link
            industry
            technologies
            description
            thumbnail {
              extension
              publicURL
            }
         }
         spotlightActivity {
          title
          link
          description 
          thumbnail {
              extension
              publicURL
            }
          }
        }
    }
  }
`
